module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"se":"(max-height: 670px)","xs":"(max-width: 500px)","s":"(max-width: 600px)","sm":"(max-width: 720px)","m":"(max-width: 821px)","md":"(max-width: 1279px)","l":"(max-width: 1536px)","minxl":"(min-width: 1920px)","xl":"(max-width: 1926px)","xxl":"(min-width: 1930px)","isLoad":"(min-width: 320px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":57371404,"webvisor":true,"trackHash":true,"afterBody":true,"defer":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
